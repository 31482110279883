//Global Functions
import { GFN } from '../js/globalFunctions';




export default async function CancelarAssinatura(user) {

    //Captura os dados do ASSAS
    const assas = JSON.parse(user.idAssas);


    //Vai no backend do waTools e captura os Inadimplentes
    const result = await fetch(`https://app.watools.com.br/api/assas/deletar-assinatura?label=waTools&id=${user.id}&customerID=${assas.userID}`);
    const data = await result.json();

    console.log(data);


    //Informa o Status da operação
    if(data.success){
        GFN.alert('success', 'Cliente removido do ASSAS');
    } else {
        GFN.alert('error', 'Erro ao remover o cliente do ASSAS');
        console.log('Erro ao remover cliente', data);
    }
}