import Header from "../painel/header";
import { useState, useEffect, useRef } from 'react';
import {FiArrowLeft, FiArrowRight} from 'react-icons/fi'
import label from '../../config/label.json';
import ReactDOM from 'react-dom/client';
import { GFN } from "../globalFunctions";



/* Captura os usuarios no backend */
const getAfiliados = async () => {
    return await fetch(`${label.backend}/api/getAllAfiliates/${sessionStorage.getItem('key')}`)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.error('error', error));
}
  



/* Lista os usuarios cadastrados na plataforma */
const ListUsers = ({users}) => {
  

    const userList = users.map(user => (
            
        <div key={user.nome} className='list-Users' style={{padding:"0.5rem"}}>
              
            <div className='afi-nome'> <span>{user.nome}</span> </div>
              
            <div className='afi-senha'> <span>{user.senha}</span> </div>
  
            <div className='afi-walet'> <span>{user.walet}</span>  </div>
  
            <div className='afi-percentage'> <span>{user.percentage}%</span> </div>
  
        </div>
    
    ));
    
    return userList;
    
};
    
  

  
/* Cria a dinamica de navegar entre as paginas de usuarios criadas */
const moveList = (direcao, users, setList, indexList, setIndex, textIndexPage, setPage, btnProximo) =>{
    
    let move = 10;
  
    btnProximo.current.classList.remove('disableMove');
  
  
    if(direcao == 'proximo'){
       
      if(Math.ceil(users.length/move) == textIndexPage){
      
        btnProximo.current.classList.add('disableMove');
      
        return;
      }
     
      setList(users.slice(indexList, indexList + move));
  
      setIndex(indexList + move);
  
      setPage(textIndexPage + 1)
    }
    else{
  
      if(textIndexPage == 1)return;
  
      setList(users.slice(indexList - (move + move), indexList - move));
  
      setIndex(indexList - move);
  
      setPage(textIndexPage - 1);
    }
  
  
}




/* Pesquisa por um usuario na listagem */
const pesquisaUser = (e, users, setList) =>{
  
    let pesquisa = e.target.value;
    let usersPesquisados = [];
  
    /* Verifica se a pesquisa esta sendo utilizada, caso sim remova o footer */
    if(pesquisa.length == 0){
      window.location.reload();
    }
    else{
      document.querySelector('.nav-list')?.remove();
    }
  
  
  
    for(let user of users){
      if(
  
        user.nome?.toLowerCase().includes(pesquisa.toLowerCase()) ||
        user.senha?.toLowerCase().includes(pesquisa.toLowerCase()) ||
        user.walet?.toLowerCase().includes(pesquisa.toLowerCase()) ||
        JSON.stringify(user.percentage)?.toLowerCase().includes(pesquisa.toLowerCase())
          
      ){
        usersPesquisados.push(user);
      }
    }
  
    setList(usersPesquisados);
  
}




/* Cria um novo afiliado */
const criaAfiliado = () =>{
    
    const criar = async() =>{
         
        const nome = document.querySelector('#afi-nome').value;
        const senha = document.querySelector('#afi-senha').value;
        const walet = document.querySelector('#afi-walet').value;
        const porcentagem = document.querySelector('#afi-porcentagem').value;

        if(nome.length < 3 || senha.length < 3 || walet.length < 3 || porcentagem.length < 1){
            GFN.alert('error', 'Preencha todos os campos');
            return;
        }

        if(porcentagem.length > 2){
            GFN.alert('error', 'Porcentagem não pode conter mais de 2 digitos');
            return;
        }

         

        /* Request no banco de dados para cadastrar o afiliado */
        var formdata = new FormData();
        formdata.append("key", sessionStorage.getItem('key'));
        formdata.append("nome", nome);
        formdata.append("senha", senha);
        formdata.append("walet", walet);
        formdata.append("porcentagem", porcentagem);


        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
      
        const res = await fetch(`${label.backend}/api/createAfiliate`, requestOptions)
        .then(response => response.json())
        .then(result =>{return result})
        .catch(error => console.error('error', error));
        

        window.location.reload();
        
    }
    


    /* Modal Afiliado*/
    const [modal, content, main, close] = GFN.modal(true);

    content.setAttribute('style', 'margin: 1% auto;')
  
    const show = (
        
        <div style={{padding:'20px 0 20px 0'}}>

            <h1>Criar Afiliado</h1>
              
            <div className="create-afiliate">

                <div>
                    <label>Nome do afiliado </label>
                    <p>(Esse nome vai ser utilizado para registrar o login das indicações dele)</p>
                    <input placeholder="Insira o nome" id="afi-nome"/>
                </div>


                <div>
                    <label>Senha</label>
                    <p>(Essa senha vai ser utilizada para ele acessar o painel de visualizão de seus indicados)</p>
                    <input placeholder="Insira a senha" id="afi-senha"/>
                </div>


                <div>
                    <label>Walet</label>
                    <p>(Ele vai ter que criar uma conta Assas e passar o ID da walet dele, ATENÇÃO: não confundiar walet com a chave da API, o afiliado jamais deve informar a chave da API dele)</p>
                    <input placeholder="Insira a walet" id="afi-walet"/>
                </div>


                <div>
                    <label>Porcentagem</label>
                    <p>(O valor de repasse combinado com o afiliado deve ser informado aqui em porcentagem, esse valor sera pago automaticamente para ele toda a vez que o indicado fazer o pagamento da assinatura)</p>
                    <input  type="number"  placeholder="Insira a porcentagem" id="afi-porcentagem"/>
                </div>

                <div style={{textAlign:"center"}}> <button onClick={criar}>Criar</button> </div>

            </div>

        </div>
    );
  
  
    ReactDOM.createRoot(main).render(show);
}




export default function Afiliados(){

    let [afiliados, setAfiliados] = useState(['']);
    let [list, setList] = useState(null);

    let [indexList, setIndex] = useState(10);
  
    const [textIndexPage, setPage] = useState(1);
  
  
    const btnProximo = useRef(null);


    /* Aguarda a requisição dos usuarios ser completada */
    useEffect(() => {
       const fetchData = async () => {
         const afiliadosData = await getAfiliados();
         setAfiliados(afiliadosData);
       };
       fetchData();
    }, []);


    /* So deixa o script proseguir quando o users obter a resposta */
    if(afiliados == '')return;
   

    /* Atribui o retorno do users a variavel */
    afiliados = afiliados.response.users.reverse();

    const main = (
        <>

            <Header/>
           
            <main>

                <div className="main">

                    <div className="main-pesquisa">
                        <input onChange={(e)=> pesquisaUser(e, afiliados, setList)} placeholder="🔎 Pesquisar por Nome, Senha, Walet ou Porcentagem"/>
                        <button style={{backgroundColor:'red'}} onClick={()=> window.location.reload()}>Limpar Busca</button>
                        <button onClick={()=>criaAfiliado()}>Criar Afiliado</button>
                        <span className='quantClient'>{afiliados.length} Afiliados Cadastrados</span>
                    </div>

                    <div className='list'><ListUsers users={list? list : afiliados.slice(0, indexList)}/></div>
             
                    <div className='nav-list'>
                        <button className={textIndexPage == 1? 'disableMove' : '' } onClick={()=>moveList('anterior', afiliados, setList, indexList, setIndex, textIndexPage,setPage, btnProximo)}><FiArrowLeft /> Anterior</button>
                        <span>Pagina {textIndexPage}</span>
                        <button ref={btnProximo} onClick={()=>moveList('proximo', afiliados, setList, indexList, setIndex, textIndexPage, setPage, btnProximo)}>Proximo <FiArrowRight /></button>
                    </div>
            
                </div>

             </main>

        </>
    );
      
    
    return main;
}