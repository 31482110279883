//Lib
import { write, utils } from "xlsx";




export default async function BaixarInadimplentes(list){
    //Armazena os clientes que possui assinatura
    const clientes_assinantes = [];

    //Extrai o ID do assas
    for(const client of list){

        if(client.idAssas){
            clientes_assinantes.push({
                ...client,
                assasID: (JSON.parse(client.idAssas).userID)
            });
        }
    }

    //Vai no backend do waTools e captura os Inadimplentes
    const result = await fetch('https://app.watools.com.br/api/assas/getInadimplentes?label=waTools');
    const data = await result.json();

    //Armazena todos os inadimplentes
    const Indadimplentes = [];


    for(const assas_user of data.users){

        //Verifica se o cliente existe no assas-
        const isExist = clientes_assinantes.find(c => c.assasID === assas_user.customer);

        //Armazena o Inadimplente
        if(isExist){
            Indadimplentes.push(isExist);
        }
    }


    // Crie uma nova planilha com os números de telefone em uma única coluna
    const worksheet = utils.json_to_sheet(Indadimplentes);
    worksheet['!cols'] = [{ width: 20 }];


    // Crie um novo objeto de pasta de trabalho e adicione a planilha
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Inadimplentes');


    // Crie um objeto Blob a partir do array de bytes do arquivo
    const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });


    // Crie um link de download com o URL do objeto Blob e dispare um evento de clique no link para iniciar o download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Inadimplentes';
    link.click();
    link.remove();
}