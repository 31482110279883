import React, {useState} from "react";
import '../../css/login.css';
import Painel from '../painel/painel';
import label from '../../config/label.json';



const submit = async (login, senha, setMsg, setHeader) => {
  if (login.length === 0 || senha.length === 0) {
    setMsg('* Erro preencha todos os campos');
    return;
  }

  var formdata = new FormData();
  formdata.append("login", login);
  formdata.append("senha", senha);

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  const res = await fetch(`${label.backend}/api/verifiLogin`, requestOptions)
  .then(response => response.json())
  .then(result => { return result })
  .catch(error => console.error('error', error));
    
   
  if(res.response.success){
    setMsg(res.response.message);

    /* Insere as informações no session storage */
    sessionStorage.setItem('session', true);
    sessionStorage.setItem('key', res.response.key);
    sessionStorage.setItem('permission', res.response.permission);

    setHeader(true);
  }
  else
  {
    setMsg(res.response.message);
  }


};



export default function Login() {
  const [logar, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [msg, setMsg] = useState('');
  const [header, setHeader] = useState(false);

  const login = (
    <main>
      <div className="main-login">
        <div><img src='images/logo.svg'></img></div>

        <div className="login-content">

          <label className="login-itens">
            <span>Login</span>
            <input value={logar} onChange={(e) => setLogin(e.target.value)} />
          </label>

          <label className="login-itens">
            <span>Senha</span>
            <input type="password" value={senha} onChange={(e) => setSenha(e.target.value)} />
          </label>

          <button onClick={() => submit(logar, senha, setMsg, setHeader)}>Logar</button>

          <span className="login-error">{msg}</span>

        </div>
      </div>
    </main>
  );

  if (header) {
    return <Painel/>;
  }

  return login;
}
